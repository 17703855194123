<!-- @format -->
<template>
  <b-card title="视频添加">
    <validation-observer ref="createProdBrand">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group
              label-align="center"
              label="视频标题"
              label-cols="1"
              label-cols-md="3"
              content-cols="6"
              content-cols-md="8"
            >
              <validation-provider
                #default="{ errors }"
                name="视频标题"
                rules="required"
              >
                <b-form-input
                  v-model="formData.title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="请输入分类名称"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label-align="center"
              label="视频地址"
              label-cols="1"
              label-cols-md="3"
              content-cols="6"
              content-cols-md="8"
            >
              <validation-provider
                #default="{ errors }"
                name="视频地址"
                rules="required"
              >
                <b-form-input
                  v-model="formData.url"
                  :state="errors.length > 0 ? false : null"
                  placeholder="请输入视频地址"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label-align="center"
              label="视频状态"
              label-cols="1"
              label-cols-md="3"
              content-cols="6"
              content-cols-md="8"
            >
              <b-form-radio-group
                v-model="formData.status"
                :options="[
                  {
                    text: '启用',
                    value: 1,
                  },
                  {
                    text: '禁用',
                    value: 0,
                  },
                ]"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitAddProduct"
              :disabled="isRequest"
            >
              提交
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import {
  reactive,
  ref,
  onUnmounted,
  getCurrentInstance,
  computed,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import vSelect from 'vue-select'
import { required, url } from '@core/utils/validations/validations'
import WangEditor from '@/views/GeneralManagement/WebManager/Article/WangEditor'
import store from '@/store'
import axios from '@/libs/axios'
import ClassManagerModule from './VideoModule'

export default {
  name: 'BrandCreate',
  components: {
    BCard,
    vSelect,
    WangEditor,
  },
  filters: {
    filterSrc(val) {
      const deleString = process.env.VUE_APP_COM_URL
      const reg = new RegExp(deleString, 'g')
      return (
        process.env.VUE_APP_BASE_URL.replace('/backend', '/')
        + val.replace(reg, '')
      )
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'article-module'
    const { router } = useRouter()
    const { proxy } = getCurrentInstance()

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) { store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ClassManagerModule) }
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) { store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME) }
    })
    const isRequest = ref(false)
    const formData = reactive({
      status: 1,
    })
    const createProdBrand = ref(null)
    const submitAddProduct = () => {
      console.log(formData,)
      store
        .dispatch('article-module/createVideo', {
          ...formData,
        })
        .then(response => {
          if (response.code === 0) {
            proxy.$bvToast.toast('添加成功', {
              title: '提示',
              variant: 'success',
              solid: true,
            })
            setTimeout(() => {
              proxy.$router.back()
            }, 1000)
          } else {
            toast.error(response.msg)
          }
        })
        .catch(() => {
          toast.error('添加失败')
        })
    }

    const articleCate = ref([])

    const catePage = ref(1)
    const catePageSize = ref(10)
    const cateTotal = ref(0)
    const hasPrevpage = computed(() => catePage.value > 1)
    const hasNextpage = computed(
      () => catePage.value * catePageSize.value < cateTotal.value
    )
    axios
      .get('/article/getArticleCateList', { params: { pageSize: 10 } })
      .then(response => {
        const { list, total } = response.data
        articleCate.value = list
        cateTotal.value = total
      })
    const searchCate = (search, loading) => {
      if (search) {
        loading(true)
        axios
          .get('/article/getArticleCateList', {
            params: { pageSize: 10, title: search },
          })
          .then(response => {
            const { list, total } = response.data
            articleCate.value = list
            cateTotal.value = total
            loading(false)
          })
      }
    }

    return {
      submitAddProduct,
      formData,
      createProdBrand,
      isRequest,
      required,
      url,
      catePage,
      articleCate,
      hasPrevpage,
      hasNextpage,
      searchCate,
    }
  },
}
</script>

<style scoped>
.style-chooser .vs__search::placeholder {
  color: #ccc;
}
.style-chooser .vs__dropdown-toggle {
  border: 1px solid red;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}
</style>
